







































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DataCard from "@/components/Common/DataCard.vue";

interface SelectOption {
  value: number;
  text: string;
}

@Component({
  components: {
    DataCard
  },
  filters: {
    capitalize(value: string) {
      if (!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class ApplicationDetail extends Vue {
  private id: string = "";
  private tarjeta: null | any = "";
  private codTarjeta: null | any;
  private loading: boolean = false;
  private loadingImage: boolean = false;
  private cardImage = new Image();
  private typeOptions: SelectOption[] = [];
  private neighboType: SelectOption[] = [];
  private genderOptions: SelectOption[] = [];
  private nationOptions: SelectOption[] = [];
  private cStateOptions: SelectOption[] = [];

  private data = {
    id: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    email: "",
    estadoCivil: 0,
    fechaNacimiento: "",
    estado: "",
    nacionalidad: 0,
    prevision: 0,
    rut: "",
    sexo: 0,
    telefono: 0,
    tipo: 0,
    codigo: "",
    direccion: {
      calle_id: 0,
      calle: "",
      numero: 0,
      block: "",
      depto: "",
      referencia: "",
      comuna: ""
    },
    qr: new Image(),
    boleta: "no",
    entidad: "",
    tipoVecino: 0
  };
  private birthDay: Date = new Date();
  private prevition: string = "";
  private canvas: any = null;

  get user() {
    return this.$store.state.user;
  }

  get fullName() {
    if (this.data) {
      return `${this.data.nombre} ${this.data.apellidoPaterno} ${this.data.apellidoMaterno}`;
    } else {
      return "";
    }
  }
  private formatRut(rut: string) {
    rut = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = rut
      .substring(0, rut.length - 1)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    const dv = rut.substring(rut.length - 1);
    return `${rutNumber}-${dv}`.toUpperCase();
  }

  private getOptionText(option: SelectOption[], id: number) {
    const result = option.find(element => element.value === id);
    return result ? result.text.toUpperCase() : this.$t("messages.no_info");
  }

  private printImage() {
    let formattedRut = this.data.rut
      .split("-")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    formattedRut = formattedRut + "-" + this.data.rut.split("-")[1];
    formattedRut = formattedRut.toUpperCase();
    // const canvas = document.getElementById("myCanvas") as any;
    // const localCardImage = new Image();
    // localCardImage.src = require("@/assets/tarjeta.jpg");
    if (this.canvas && this.data.qr.complete) {
      this.canvas.width = 750;
      this.canvas.height = 1330;
      const ctx = this.canvas.getContext("2d");
      ctx.font = "bold 35px Verdana";
      ctx.fillStyle = "#fff";
      ctx.drawImage(this.cardImage, 0, 0);
      ctx.textAlign = "center";
      ctx.fillText(
        this.data.nombre.toUpperCase(),
        this.canvas.width / 2 - 5,
        850
      );
      ctx.fillText(
        this.data.apellidoPaterno.toUpperCase() +
          " " +
          this.data.apellidoMaterno.toUpperCase(),
        this.canvas.width / 2 - 5,
        900
      );
      ctx.fillText(formattedRut, this.canvas.width / 2 - 5, 980);
      ctx.drawImage(
        this.data.qr,
        0,
        0,
        this.data.qr.width,
        this.data.qr.height,
        this.canvas.width / 3.85,
        325,
        this.data.qr.width * 1.1,
        this.data.qr.height * 1.1
      );
      ctx.font = "bold 35px Verdana";
      ctx.fillStyle = "#fff";
      if (this.data.codigo) {
        ctx.fillText(
          this.data.codigo,
          this.canvas.width / 2 - 5,
          310 + (this.data.qr.height * 0.75) / 2 + 330
        );
      } else {
        ctx.fillText(
          "1234567891",
          this.canvas.width / 2 - 20,
          320 + (this.data.qr.height * 0.75) / 2 + 330
        );
      }

      const element = document.createElement("a");
      element.style.display = "none";
      element.setAttribute("href", this.canvas.toDataURL("image/png"));
      element.setAttribute("download", "Tarjeta.png");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  private async generarTarjeta() {
    this.$q.loading.show();
    await new Promise((resolve, reject) => {
      let formattedRut = this.data.rut
        .split("-")[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      formattedRut = formattedRut + "-" + this.data.rut.split("-")[1];
      formattedRut = formattedRut.toUpperCase();

      // const canvas = document.getElementById("myCanvas") as any;

      if (this.canvas) {
        this.data.qr.onload = () => {
          this.canvas.width = 750;
          this.canvas.height = 1330;
          const ctx = this.canvas.getContext("2d");
          // const localCardImage = new Image();
          // localCardImage.src = require("@/assets/tarjeta.jpg");
          this.cardImage.onload = () => {
            ctx.drawImage(this.cardImage, 0, 0);
            ctx.font = "bold 35px Verdana";
            ctx.fillStyle = "#fff";
            ctx.textAlign = "center";
            ctx.fillText(
              this.data.nombre.toUpperCase(),
              this.canvas.width / 2 - 5,
              850
            );
            ctx.fillText(
              this.data.apellidoPaterno.toUpperCase() +
                " " +
                this.data.apellidoMaterno.toUpperCase(),
              this.canvas.width / 2 - 5,
              900
            );

            ctx.fillText(formattedRut, this.canvas.width / 2 - 5, 980);
            ctx.drawImage(
              this.data.qr,
              0,
              0,
              this.data.qr.width,
              this.data.qr.height,
              this.canvas.width / 3.85,
              325,
              this.data.qr.width * 1.1,
              this.data.qr.height * 1.1
            );
            ctx.font = "bold 35px Verdana";
            ctx.fillStyle = "#fff";

            if (this.data.codigo) {
              ctx.fillText(
                this.data.codigo,
                this.canvas.width / 2 - 5,
                310 + (this.data.qr.height * 0.75) / 2 + 330
              );
            } else {
              ctx.fillText(
                "1234567891",
                this.canvas.width / 2 - 20,
                400 + (this.data.qr.height * 0.75) / 2 + 330
              );
            }
            this.tarjeta = this.canvas.toDataURL("image/jpg");
            this.$q.loading.hide();
            resolve(this.canvas.toDataURL("image/png"));
          };
        };
      }
    });
  }

  private async printImage2() {
    let formattedRut = this.data.rut
      .split("-")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    formattedRut = formattedRut + "-" + this.data.rut.split("-")[1];
    formattedRut = formattedRut.toUpperCase();

    const canvas = document.getElementById("myCanvas") as any;

    if (canvas && this.data.qr.complete) {
      canvas.width = 750;
      canvas.height = 1330;
      const ctx = canvas.getContext("2d");
      const localCardImage = new Image();
      localCardImage.src = require("@/assets/tarjeta.png");
      await ctx.drawImage(localCardImage, 0, 0);
      ctx.font = "bold 35px Verdana";
      ctx.fillStyle = "#fff";
      ctx.textAlign = "center";
      ctx.fillText(
        this.data.nombre.toUpperCase(),
        this.canvas.width / 2 - 5,
        850
      );
      ctx.fillText(
        this.data.apellidoPaterno.toUpperCase(),
        this.canvas.width / 2 - 5,
        900
      );
      ctx.fillText(formattedRut, this.canvas.width / 2 - 5, 980);
      ctx.drawImage(
        this.data.qr,
        0,
        0,
        this.data.qr.width,
        this.data.qr.height,
        canvas.width / 4 - 20,
        345,
        this.data.qr.width * 1.3,
        this.data.qr.height * 1.3
      );
      ctx.font = "bold 30px Verdana";
      ctx.fillStyle = "#fff";
      if (this.data.codigo) {
        ctx.fillText(
          this.data.codigo,
          canvas.width / 2 - 20,
          400 + (this.data.qr.height * 0.75) / 2 + 330
        );
      } else {
        ctx.fillText(
          "1234567891",
          canvas.width / 2 - 20,
          400 + (this.data.qr.height * 0.75) / 2 + 330
        );
      }
      this.tarjeta = canvas.toDataURL("image/jpg");
      this.$store.commit("SET_TARJETA", canvas.toDataURL("image/jpg"));
    }
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.id &&
      this.$router.currentRoute.params.id != ""
    ) {
      this.id = this.$router.currentRoute.params.id;
      this.$q.loading.show({
        delay: 100 // ms
      });

      this.$axios
        .get("vecino/profile?id=" + this.id)
        .then(res => {
          Object.assign(this.data, res.data);

          const qr = new Image();
          if (this.data.qr) {
            qr.src = `data: ${this.data.qr}`;
            this.data.qr = qr;
            this.generarTarjeta();
            this.cardImage.src = require("@/assets/tarjeta.png");
          }
          var ua = navigator.userAgent.toLowerCase();
          let birthDate = "now";
          if (ua.indexOf("safari") != -1) {
            if (ua.indexOf("chrome") > -1) {
              birthDate = (this.data.fechaNacimiento.substring(0, 4) +
                "-" +
                this.data.fechaNacimiento.substring(5, 7) +
                "-" +
                this.data.fechaNacimiento.substring(8, 10) +
                " 12:00:00") as any;
            } else {
              birthDate = (this.data.fechaNacimiento.substring(0, 4) +
                "-" +
                this.data.fechaNacimiento.substring(5, 7) +
                "-" +
                this.data.fechaNacimiento.substring(8, 10) +
                "T12:00:00") as any;
            }
          } else if (ua.indexOf("mozilla") != -1) {
            //Usa Mozilla
            birthDate = this.data.fechaNacimiento
          }
          this.birthDay = new Date(birthDate);
          this.loading = true;
        })
        .catch(res => {
          if (this.$router.currentRoute.name == "ApplicationsList") {
            this.$router.go(0);
          } else {
            this.$router.push({ name: "ApplicationsList" }).catch(err => {});
          }
        })
        .finally(() => {
          this.$q.loading.hide();
          if (this.data.qr) {
            this.canvas = document.getElementById("myCanvas") as any;
            this.generarTarjeta();
          }
        });

      this.$q.loading.show({
        delay: 100 // ms
      });

      this.genderOptions.push(
        { value: 1, text: "Masculino" },
        { value: 0, text: "Femenino" },
        { value: 2, text: "Otro" },
        { value: 3, text: "Prefiero no decirlo" },
        { value: 4, text: "No Binarie" }
      );
      this.neighboType.push(
        { value: 1, text: this.$t("meta.neighbor_types.neighbor").toString() },
        { value: 2, text: this.$t("meta.neighbor_types.worker").toString() },
        {
          value: 3,
          text: this.$t("meta.neighbor_types.worker_cmds").toString()
        }
      );
      this.cStateOptions.push(
        { value: 0, text: this.$t("meta.status.single").toString() },
        { value: 1, text: this.$t("meta.status.married").toString() },
        { value: 2, text: this.$t("meta.status.union").toString() },
        { value: 3, text: this.$t("meta.status.divorced").toString() },
        { value: 4, text: this.$t("meta.status.null").toString() },
        { value: 5, text: this.$t("meta.status.widow").toString() }
      );
      this.$q.loading.show({
        delay: 100 // ms
      });
      this.$axios("pais")
        .then(res => {
          if (this.$store.state.user.lenguage === "es") {
            this.nationOptions.push({
              value: res.data[46].id,
              text: res.data[46].nombre_es
            });
          } else if (this.$store.state.user.lenguage === "en") {
            this.nationOptions.push({
              value: res.data[46].id,
              text: res.data[46].nombre_en
            });
          }
          res.data.forEach((country: any, index: number) => {
            if (res.data.length - 1 !== index && index !== 46) {
              if (this.$store.state.user.lenguage === "es") {
                this.nationOptions.push({
                  value: country.id,
                  text: country.nombre_es
                });
              } else if (this.$store.state.user.lenguage === "en") {
                this.nationOptions.push({
                  value: country.id,
                  text: country.nombre_en
                });
              }
            }
          });
        })
        .finally(() => {
          this.$q.loading.hide();
        });

      // if (this.$store.state.tarjeta && this.$store.state.tarjeta != "") {
      //   this.tarjeta = this.$store.state.tarjeta;
      // } else if (
      //   localStorage.getItem("tarjeta") &&
      //   localStorage.getItem("tarjeta") != ""
      // ) {
      //   this.tarjeta = localStorage.getItem("tarjeta");
      // }
    } else {
      if (this.$router.currentRoute.name == "ApplicationsList") {
        this.$router.go(0);
      } else {
        this.$router.push({ name: "ApplicationsList" }).catch(err => {});
      }
    }
  }

  get age() {
    const today = new Date();
    const date = this.birthDay;
    if (date) {
      const age = (today.getTime() - date.getTime()) / (1000 * 3600 * 24 * 365);
      return age;
    }
    return 0;
  }

  private cardMenor() {
    if (this.age < 18) {
      return "row justify-center col-12 col-md-12";
    } else {
      return "col-12 col-md-7";
    }
  }
}
